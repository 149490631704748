import React from "react";
import "./submit.css";

function SubmitPage() {
  if (sessionStorage.getItem("google_sheet_id") == null) {
    window.location.href = "/";
  }
  let message = sessionStorage.getItem("welcome_message");
  message = message.replace("<<Name>>", sessionStorage.getItem("Name"));
  sessionStorage.removeItem("Name");
  try {
    sessionStorage.removeItem("google_sheet_id");
    sessionStorage.removeItem("google_passing_percent");
    sessionStorage.removeItem("welcome_message");
    sessionStorage.removeItem("Email");
    sessionStorage.removeItem("Authorize Email");
  } catch (e) { }
  return (
    <div className="completed-page">
      <div className="completed-container">
        <div>
          <i className="fa-solid fa-square-check"></i>
        </div>
        <h2 className="completed-heading">Test Completed!</h2>
        <p className="completed-message">{message}</p>
      </div>
    </div>
  );
}

export default SubmitPage;
