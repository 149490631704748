import React from 'react'
import SubmitPage from '../components/submit/submit'

function ResponsePage() {
    return (
        <>
            <SubmitPage />
        </>
    )
}

export default ResponsePage