import React, { useEffect, useState } from "react";
import TextInputQuestion from "./text/textquestion";
import RadioQuestion from "./radio_question/radio_question";
import CheckboxQuestion from "./checkbox/checkbox";
import NumberInputQuestion from "./number/numberinput_question";
import EmailInputQuestion from "./email_question/emailQuestion";
import TextareaQuestion from "./textarea/textarea_question";
import DateInputQuestion from "./date/datequestion";
import TimeInputQuestion from "./time/timequestion";
import "./renderQuestion.css";

function RenderQuestion({
  mcqData,
  sectionDetailsHandle,
  sectionDetails,
  onSubmitData,
  testCompleted,
}) {
  const sections = Object.keys(mcqData);
  const [prevShow, setPrevShow] = useState(false);
  const [ques, setQues] = useState([]);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [buttonDisable, setButtonDisable] = useState(false);
  const [attemptedQuestions, setAttemptedQuestions] = useState([]); // New state variable

  const handleNext = () => {
    if (currentSection === 0) {
      setCurrentSection(1);
      setCurrentQuestion(0);
      return;
    }
    if (currentQuestion < mcqData[sections[currentSection]].length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (currentSection < sections.length - 1) {
      setCurrentSection(currentSection + 1);
      setCurrentQuestion(0);
      setCurrentQuestionIndex(0);
    }
  };

  const handlePrev = () => {
    if (currentSection === 1 && currentQuestion == 0) {
      setCurrentSection(0);
      setCurrentQuestion(0);
      return;
    }
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      setCurrentQuestion(mcqData[sections[currentSection - 1]].length - 1);
    }
  };

  const handleFormDataChange = (key, value) => {
    setFormData((data) => {
      return { ...data, [key]: value };
    });
    if (currentSection === 1) {
      setAttemptedQuestions([...attemptedQuestions, currentQuestion]);
    } else if (currentSection > 1) {
      let total = 0;
      for (let i = 1; i < currentSection; i++) {
        total += mcqData[sections[i]].length;
      }
      if (currentQuestion === 0) {
        setAttemptedQuestions([...attemptedQuestions, total]);
      } else {
        setAttemptedQuestions([...attemptedQuestions, total + currentQuestion]);
      }
    }
  };

  /** This will get called when clicked on submit button or when timer is over */
  const submitAnswer = () => {
    setButtonDisable(true);
    onSubmitData(formData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sectionDetailsHandle(sectionDetails[`Section${currentSection + 1}`]);
  }, [sectionDetails, currentSection]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    if (testCompleted) {
      const data = {
        ...formData,
      };
      onSubmitData(data);
    }
  }, [testCompleted]);

  useEffect(() => {
    let totalQuestions = 0;
    for (let i = 1; i < sections.length; i++) {
      totalQuestions += mcqData[sections[i]].length;
    }
    const countArray = Array(totalQuestions)
      .fill(null)
      .map((_, index) => index + 1);
    setQues([...countArray]);
  }, [mcqData]);

  useEffect(() => {
    if (currentSection === 0) {
      setPrevShow(false);
    } else {
      setPrevShow(true);
    }
  }, [currentSection]);

  if (sections.length === 0) {
    return <></>;
  }

  const currentSectionData = mcqData[sections[currentSection]];
  const currentQuestionData = currentSectionData[currentQuestion];
  const questionNumber = currentQuestionData["questionNumber"];
  const questionType = currentQuestionData["questionType"];
  const required = currentQuestionData["required"];

  if (sections.length === 0) {
    return <></>;
  }

  return (
    <>
      {currentSection > 0 ? (
        questionType === "Text" ? (
          <TextInputQuestion
            key={questionNumber}
            question={currentQuestionData}
            onFormDataChange={handleFormDataChange}
            formData={formData}
          />
        ) : questionType === "Radio" ? (
          <RadioQuestion
            key={questionNumber}
            question={currentQuestionData}
            onFormDataChange={handleFormDataChange}
            formData={formData}
          />
        ) : questionType === "Checkbox" ? (
          <CheckboxQuestion
            key={questionNumber}
            question={currentQuestionData}
            onFormDataChange={handleFormDataChange}
            formData={formData}
          />
        ) : questionType === "Number" ? (
          <NumberInputQuestion
            key={questionNumber}
            question={currentQuestionData}
            onFormDataChange={handleFormDataChange}
            formData={formData}
          />
        ) : questionType === "Email" ? (
          <EmailInputQuestion
            key={questionNumber}
            question={currentQuestionData}
            onFormDataChange={handleFormDataChange}
            formData={formData}
          />
        ) : questionType === "TextArea" ? (
          <TextareaQuestion
            key={questionNumber}
            question={currentQuestionData}
            onFormDataChange={handleFormDataChange}
            formData={formData}
          />
        ) : questionType === "Date" ? (
          <DateInputQuestion
            key={questionNumber}
            question={currentQuestionData}
            onFormDataChange={handleFormDataChange}
            formData={formData}
          />
        ) : questionType === "Time" ? (
          <TimeInputQuestion
            key={questionNumber}
            question={currentQuestionData}
            onFormDataChange={handleFormDataChange}
            formData={formData}
          />
        ) : (
          ""
        )
      ) : (
        mcqData["Section1"].map((data) => {
          const questionType = data.questionType;
          const questionNumber = data.questionNumber;
          const currentQuestionData = data;
          return questionType === "Text" ? (
            <TextInputQuestion
              key={questionNumber}
              question={currentQuestionData}
              onFormDataChange={handleFormDataChange}
              formData={formData}
            />
          ) : questionType === "Radio" ? (
            <RadioQuestion
              key={questionNumber}
              question={currentQuestionData}
              onFormDataChange={handleFormDataChange}
              formData={formData}
            />
          ) : questionType === "Checkbox" ? (
            <CheckboxQuestion
              key={questionNumber}
              question={currentQuestionData}
              onFormDataChange={handleFormDataChange}
              formData={formData}
            />
          ) : questionType === "Number" ? (
            <NumberInputQuestion
              key={questionNumber}
              question={currentQuestionData}
              onFormDataChange={handleFormDataChange}
              formData={formData}
            />
          ) : questionType === "Email" ? (
            <EmailInputQuestion
              key={questionNumber}
              question={currentQuestionData}
              onFormDataChange={handleFormDataChange}
              formData={formData}
            />
          ) : questionType === "TextArea" ? (
            <TextareaQuestion
              key={questionNumber}
              question={currentQuestionData}
              onFormDataChange={handleFormDataChange}
              formData={formData}
            />
          ) : questionType === "Date" ? (
            <DateInputQuestion
              key={questionNumber}
              question={currentQuestionData}
              onFormDataChange={handleFormDataChange}
              formData={formData}
            />
          ) : questionType === "Time" ? (
            <TimeInputQuestion
              key={questionNumber}
              question={currentQuestionData}
              onFormDataChange={handleFormDataChange}
              formData={formData}
            />
          ) : (
            ""
          );
        })
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {prevShow ? <button onClick={handlePrev}>Previous</button> : ""}
        {currentSection === sections.length - 1 &&
          currentQuestion === currentSectionData.length - 1 ? (
          <button
            className="submit"
            onClick={submitAnswer}
            disabled={buttonDisable}
          >
            Submit
          </button>
        ) : (
          <button
            onClick={handleNext}
            className="show-hide"
            disabled={
              (formData[`Question${questionNumber}`] === undefined || formData[`Question${questionNumber}`] == '' ||
                (typeof formData[`Question${questionNumber}`] === "object" &&
                  formData[`Question${questionNumber}`].length === 0) ||
                (typeof formData[`Question${questionNumber}`] === "string" &&
                  formData[`Question${questionNumber}`].trim() === "")) &&
              required === true
            }
          >
            Next
          </button>
        )}
      </div>

      {mcqData && currentSection > 0 && (
        <div className="mainpage" style={{ marginTop: "10px" }}>
          {ques.map((data, index) => {
            return (
              <div
                className={`pagination ${attemptedQuestions.includes(index) ? "attempted" : ""
                  }`}
              >
                {index + 1}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default RenderQuestion;
